import type { ContainerWidth, SpacingPreset, Breakpoint } from './types'

export const CONTAINER_SIZES: Record<ContainerWidth, string> = {
  narrow: '40rem',
  content: '60rem',
  wide: '80rem',
  full: '100%',
}

export const SPACING_VALUES: Record<SpacingPreset, string> = {
  none: '0',
  small: 'var(--spacing-4)',
  medium: 'var(--spacing-8)',
  large: 'var(--spacing-12)',
  xlarge: 'var(--spacing-16)',
}

export const BREAKPOINT_ORDER: Breakpoint[] = ['mobile', 'tablet', 'desktop', 'wide']

// TODO: Replace with centralized breakpoints from config system
// See src/config/ROADMAP.md for the configuration consolidation plan
export const BREAKPOINT_VALUES = {
  mobile: 0,
  tablet: 768,
  desktop: 1024,
  wide: 1280,
}
