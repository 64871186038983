import type {
  MotionConfig,
  MotionPreset,
  MotionDirection,
  MotionTiming,
  MotionDuration,
  MotionComponentType,
  MotionComponentConfig,
  MotionPerformanceLevel,
} from './types'

// Default configurations
export const DEFAULT_MOTION_CONFIG: MotionConfig = {
  preset: 'fade',
  direction: 'up',
  timing: 'ease',
  duration: 'normal',
  delay: 0,
  threshold: 0.1,
}

// Timing configurations
export const MOTION_TIMINGS: Record<MotionTiming, string> = {
  linear: 'linear',
  ease: 'cubic-bezier(0.4, 0, 0.2, 1)',
  bounce: 'cubic-bezier(0.68, -0.55, 0.265, 1.55)',
  spring: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
}

// Duration values in milliseconds
export const MOTION_DURATIONS: Record<MotionDuration, number> = {
  fast: 200,
  normal: 300,
  slow: 500,
}

// Animation presets
export const MOTION_PRESETS: Record<
  MotionPreset,
  Record<
    string,
    | Record<string, number | string | boolean>
    | ((direction: MotionDirection) => Record<string, number | boolean>)
  >
> = {
  none: {
    initial: {},
    animate: {},
    exit: {},
  },
  fade: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  },
  scale: {
    initial: { scale: 0.95, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0.95, opacity: 0 },
  },
  slide: {
    initial: (direction: MotionDirection) => ({
      x: direction === 'left' ? -20 : direction === 'right' ? 20 : 0,
      y: direction === 'up' ? 20 : direction === 'down' ? -20 : 0,
      opacity: 0,
    }),
    animate: {
      x: 0,
      y: 0,
      opacity: 1,
    },
    exit: (direction: MotionDirection) => ({
      x: direction === 'left' ? 20 : direction === 'right' ? -20 : 0,
      y: direction === 'up' ? -20 : direction === 'down' ? 20 : 0,
      opacity: 0,
    }),
  },
}

// CSS classes for basic animations (fallback when JS is disabled)
export const MOTION_CLASSES: Record<MotionPreset, string> = {
  none: '',
  fade: 'animate-fade',
  scale: 'animate-scale',
  slide: 'animate-slide',
}

// Performance thresholds
export const PERFORMANCE_THRESHOLDS: Record<MotionPerformanceLevel, number> = {
  high: 55, // fps
  medium: 30,
  low: 20,
}

// Component configurations
export const MOTION_COMPONENT_CONFIG: Record<MotionComponentType, MotionComponentConfig> = {
  gesture: {
    priority: 1,
    maxInstances: 5,
    performanceImpact: 2,
    reducedMotionFallback: 'none',
  },
  parallax: {
    priority: 2,
    maxInstances: 3,
    performanceImpact: 3,
    reducedMotionFallback: 'none',
  },
  rotate: {
    priority: 2,
    maxInstances: 4,
    performanceImpact: 2,
    reducedMotionFallback: 'none',
  },
  slider: {
    priority: 1,
    maxInstances: 2,
    performanceImpact: 3,
    reducedMotionFallback: 'fade',
  },
  split: {
    priority: 3,
    maxInstances: 2,
    performanceImpact: 4,
    reducedMotionFallback: 'simple',
  },
  stagger: {
    priority: 2,
    maxInstances: 3,
    performanceImpact: 3,
    reducedMotionFallback: 'simple',
  },
  wipe: {
    priority: 3,
    maxInstances: 2,
    performanceImpact: 3,
    reducedMotionFallback: 'fade',
  },
  infinite: {
    priority: 4,
    maxInstances: 1,
    performanceImpact: 5,
    reducedMotionFallback: 'none',
  },
  sticky: {
    priority: 1,
    maxInstances: 5,
    performanceImpact: 1,
    reducedMotionFallback: 'none',
  },
  transition: {
    priority: 1,
    maxInstances: 5,
    performanceImpact: 2,
    reducedMotionFallback: 'fade',
  },
  pulse: {
    priority: 3,
    maxInstances: 3,
    performanceImpact: 2,
    reducedMotionFallback: 'none',
  },
  reveal: {
    priority: 2,
    maxInstances: 5,
    performanceImpact: 2,
    reducedMotionFallback: 'fade',
  },
  scramble: {
    priority: 4,
    maxInstances: 2,
    performanceImpact: 4,
    reducedMotionFallback: 'simple',
  },
  scroll: {
    priority: 1,
    maxInstances: 5,
    performanceImpact: 2,
    reducedMotionFallback: 'none',
  },
  counter: {
    priority: 2,
    maxInstances: 4,
    performanceImpact: 1,
    reducedMotionFallback: 'none',
  },
  gradient: {
    priority: 3,
    maxInstances: 2,
    performanceImpact: 3,
    reducedMotionFallback: 'fade',
  },
}

// Stagger configurations
export const STAGGER_CONFIG = {
  baseDelay: 50,
  maxDelay: 300,
  maxItems: 10,
}

// Scroll configurations
export const SCROLL_CONFIG = {
  throttle: 16, // ms
  threshold: 0.1,
  rootMargin: '20%',
}

// Touch detection
export const TOUCH_CONFIG = {
  minSwipeDistance: 50,
  maxSwipeTime: 300,
}
