'use client'

import React, { createContext, useContext, useState, useCallback } from 'react'
import { cn } from '@/utils/ui'
import type { ThemeContextType, ThemeMode, ColorScheme, ThemeVariant } from './types'
import { COLOR_SCHEMES, THEME_VARIANTS, DEFAULT_THEME_CONFIG } from './constants'

// Create the context
const ThemeContext = createContext<ThemeContextType | null>(null)

// Provider component
export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Theme state
  const [mode, setMode] = useState<ThemeMode>(DEFAULT_THEME_CONFIG.mode)
  const [colorScheme, setColorScheme] = useState<ColorScheme>(DEFAULT_THEME_CONFIG.colorScheme)
  const [variant, setVariant] = useState<ThemeVariant>(DEFAULT_THEME_CONFIG.variant)
  const [systemTheme, setSystemTheme] = useState<'dark' | 'light'>('light')

  // Detect system theme
  React.useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    const updateSystemTheme = (e: MediaQueryListEvent | MediaQueryList) => {
      setSystemTheme(e.matches ? 'dark' : 'light')
    }

    // Initial check
    updateSystemTheme(mediaQuery)

    // Listen for changes
    mediaQuery.addEventListener('change', updateSystemTheme)
    return () => mediaQuery.removeEventListener('change', updateSystemTheme)
  }, [])

  // Compute if we're in dark mode
  const isDarkMode = React.useMemo(() => {
    if (mode === 'system') return systemTheme === 'dark'
    return mode === 'dark'
  }, [mode, systemTheme])

  // Utility to generate theme classes
  const getThemeClass = useCallback(
    (options?: { mode?: ThemeMode; scheme?: ColorScheme; variant?: ThemeVariant }) => {
      const activeMode = options?.mode || mode
      const activeScheme = options?.scheme || colorScheme
      const activeVariant = options?.variant || variant

      return cn(
        // Base theme class
        'transition-colors duration-200',

        // Mode-based classes
        {
          dark: activeMode === 'dark' || (activeMode === 'system' && systemTheme === 'dark'),
          light: activeMode === 'light' || (activeMode === 'system' && systemTheme === 'light'),
        },

        // Color scheme classes
        COLOR_SCHEMES[activeScheme],

        // Variant classes
        THEME_VARIANTS[activeVariant],
      )
    },
    [mode, colorScheme, variant, systemTheme],
  )

  const value: ThemeContextType = {
    mode,
    setMode,
    colorScheme,
    setColorScheme,
    variant,
    setVariant,
    getThemeClass,
    isDarkMode,
    systemTheme,
  }

  return (
    <ThemeContext.Provider value={value}>
      <div className={getThemeClass()}>{children}</div>
    </ThemeContext.Provider>
  )
}

// Custom hook to use theme context
export const useTheme = () => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider')
  }
  return context
}

// Export types
export type { ThemeContextType, ThemeMode, ColorScheme, ThemeVariant }
