'use client'

import { useEffect } from 'react'
import type { SiteTheme } from '@/payload-types'

interface ThemeVariablesProviderProps {
  theme: Partial<SiteTheme>
  children: React.ReactNode
}

/**
 * Client component that applies theme variables from CMS
 * Used to update CSS variables after hydration
 */
export function ThemeVariablesProvider({ theme, children }: ThemeVariablesProviderProps) {
  // Apply theme variables on client-side
  useEffect(() => {
    if (!theme?.layout) return
    
    const root = document.documentElement
    const { layout } = theme

    // Format and apply variables
    function formatValue(value: number | undefined, unit: string | undefined, defaultValue: string): string {
      if (typeof value !== 'number' || !unit) return defaultValue
      return `${value}${unit}`
    }
    
    // Update content max width
    const contentMax = formatValue(
      layout.contentMax?.value,
      layout.contentMax?.unit,
      '70rem'
    )
    root.style.setProperty('--content-max', contentMax)

    // Update minimum gutter width
    const gutterMin = formatValue(
      layout.gutterMin?.value,
      layout.gutterMin?.unit,
      '3vw'
    )
    root.style.setProperty('--gutter-min', gutterMin)

    // Update edge padding
    const edgePadding = formatValue(
      layout.edgePadding?.value,
      layout.edgePadding?.unit,
      '0.5rem'
    )
    root.style.setProperty('--edge-padding', edgePadding)
  }, [theme])

  return <>{children}</>
}
