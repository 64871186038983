import type { ThemeMode, ColorScheme, ThemeVariant } from './types'

export const THEME_MODES: Record<ThemeMode, string> = {
  light: 'light',
  dark: 'dark',
  system: 'system',
}

export const COLOR_SCHEMES: Record<ColorScheme, string> = {
  default: 'theme-default',
  primary: 'theme-primary',
  secondary: 'theme-secondary',
  accent: 'theme-accent',
}

export const THEME_VARIANTS: Record<ThemeVariant, string> = {
  base: 'theme-base',
  elevated: 'theme-elevated',
  sunken: 'theme-sunken',
}

export const DEFAULT_THEME_CONFIG = {
  mode: 'system' as ThemeMode,
  colorScheme: 'default' as ColorScheme,
  variant: 'base' as ThemeVariant,
}
