'use client'

import { Button } from '@/components/ui/Button/index'
import { cn } from '@/utils/ui'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { getLinkHref, type CMSLinkType } from './index'

// Modified CMSLinkType for client-side use that accepts actual handlers
export type ClientCMSLinkType = CMSLinkType & {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  onMouseEnter?: React.MouseEventHandler<HTMLAnchorElement>
  onMouseLeave?: React.MouseEventHandler<HTMLAnchorElement>
}

// Global registry for event handlers
type EventHandlers = {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  onMouseEnter?: React.MouseEventHandler<HTMLAnchorElement>
  onMouseLeave?: React.MouseEventHandler<HTMLAnchorElement>
}

const eventHandlerRegistry = new Map<string, EventHandlers>()

// Register event handlers and get an ID to use in the server component
export function registerLinkEventHandlers(handlers: EventHandlers): string {
  const id = `link-${Math.random().toString(36).substring(2, 9)}`
  eventHandlerRegistry.set(id, handlers)
  return id
}

/**
 * Client version of CMSLink that supports event handlers
 */
export const ClientCMSLink: React.FC<ClientCMSLinkType> = (props) => {
  const {
    type,
    ui = 'inline',
    children,
    className,
    label,
    newTab,
    reference,
    size: sizeFromProps,
    url,
    onClick,
    onMouseEnter,
    onMouseLeave,
    eventHandlerId,
    ...rest
  } = props

  // If we have an eventHandlerId, look up the handlers in the registry
  const eventHandlers = eventHandlerId
    ? eventHandlerRegistry.get(eventHandlerId) || {}
    : { onClick, onMouseEnter, onMouseLeave }

  const href = getLinkHref({ type, reference, url })

  if (!href && !url) return null

  const newTabProps = newTab ? { rel: 'noopener noreferrer', target: '_blank' } : {}

  /* Ensure we don't break any styles set by richText */
  if (ui === 'inline') {
    return (
      <Link className={cn(className)} href={href} {...newTabProps} {...eventHandlers} {...rest}>
        {label && label}
        {children && children}
      </Link>
    )
  }

  return (
    <Button asChild className={className} size={sizeFromProps ?? 'default'} variant={ui}>
      <Link className={cn(className)} href={href} {...newTabProps} {...eventHandlers} {...rest}>
        {label && label}
        {children && children}
      </Link>
    </Button>
  )
}

/**
 * ClientCMSLinkHydration component that hydrates the server-rendered markers with the client component
 */
export function ClientCMSLinkHydration() {
  const [_mounted, setMounted] = useState(false)

  useEffect(() => {
    // Only run on client-side
    setMounted(true)

    // Find all link markers and replace them with the client component
    const replaceClientLinks = () => {
      const markers = document.querySelectorAll('[data-cms-link="client"]')

      markers.forEach((marker) => {
        if (!(marker instanceof HTMLElement)) return

        try {
          // Parse the JSON props
          const propsStr = marker.getAttribute('data-props') || '{}'
          const props = JSON.parse(propsStr) as CMSLinkType

          // Create client props with the same shape
          const clientProps: ClientCMSLinkType = {
            ...props,
          }

          // Create a React root and render the client component
          const root = document.createElement('span')
          root.style.display = 'contents'

          // Replace the marker with our new element
          marker.parentNode?.replaceChild(root, marker)

          // Use React to render our component to the new element
          const clientRoot = createRoot(root)
          clientRoot.render(<ClientCMSLink {...clientProps} />)
        } catch (err) {
          console.error('Failed to hydrate CMSLink', err)
        }
      })
    }

    // Run once on mount
    replaceClientLinks()

    // Also set up a mutation observer to catch any that might be added later
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.addedNodes.length) {
          replaceClientLinks()
          break
        }
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })

    return () => observer.disconnect()
  }, [])

  return null
}
