'use client'

import React, { createContext, useContext, useState, useCallback, useEffect, useRef } from 'react'
import type {
  MotionContextType,
  MotionEnvironment,
  MotionComponent,
  MotionComponentType,
} from './types'
import { PERFORMANCE_THRESHOLDS, MOTION_COMPONENT_CONFIG, STAGGER_CONFIG } from './constants'

// Create the context
const MotionContext = createContext<MotionContextType | null>(null)

// Provider component
export const MotionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Environment state
  const [environment, setEnvironment] = useState<MotionEnvironment>({
    performanceLevel: 'high',
    reducedMotion: false,
    prefersReducedMotion: false,
    isTouch: false,
    scrollProgress: 0,
    viewportHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
    viewportWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
    fps: 60,
    activeAnimations: 0,
    lastFrameTime: 0,
  })

  // Component tracking
  const [components] = useState(new Map<string, MotionComponent>())
  const [isPaused, setIsPaused] = useState(false)

  // Performance monitoring
  const frameRef = useRef<number>(0)
  const lastTimeRef = useRef(performance.now())
  const framesRef = useRef(0)

  // Monitor performance
  useEffect(() => {
    const checkPerformance = () => {
      const now = performance.now()
      const delta = now - lastTimeRef.current

      framesRef.current++

      if (delta >= 1000) {
        const fps = framesRef.current * (1000 / delta)
        setEnvironment((prev) => ({
          ...prev,
          fps,
          performanceLevel:
            fps >= PERFORMANCE_THRESHOLDS.high
              ? 'high'
              : fps >= PERFORMANCE_THRESHOLDS.medium
                ? 'medium'
                : 'low',
        }))

        framesRef.current = 0
        lastTimeRef.current = now
      }

      frameRef.current = requestAnimationFrame(checkPerformance)
    }

    frameRef.current = requestAnimationFrame(checkPerformance)
    return () => cancelAnimationFrame(frameRef.current)
  }, [])

  // Monitor reduced motion preference
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')
    const updateMotionPreference = (e: MediaQueryListEvent | MediaQueryList) => {
      setEnvironment((prev) => ({
        ...prev,
        prefersReducedMotion: e.matches,
        reducedMotion: e.matches,
      }))
    }

    updateMotionPreference(mediaQuery)
    mediaQuery.addEventListener('change', updateMotionPreference)
    return () => mediaQuery.removeEventListener('change', updateMotionPreference)
  }, [])

  // Monitor scroll
  useEffect(() => {
    let ticking = false

    const updateScroll = () => {
      const progress = window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)
      setEnvironment((prev) => ({ ...prev, scrollProgress: progress }))
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        requestAnimationFrame(updateScroll)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  // Component registration
  const registerComponent = useCallback(
    (type: MotionComponentType, priority = MOTION_COMPONENT_CONFIG[type].priority) => {
      const id = Math.random().toString(36).substr(2, 9)
      components.set(id, {
        id,
        type,
        priority,
        isActive: false,
      })
      return id
    },
    [components],
  )

  const unregisterComponent = useCallback(
    (id: string) => {
      components.delete(id)
    },
    [components],
  )

  // Animation coordination
  const shouldAnimate = useCallback(
    (id: string) => {
      const component = components.get(id)
      if (!component) return false

      const config = MOTION_COMPONENT_CONFIG[component.type]
      const activeOfType = Array.from(components.values()).filter(
        (c) => c.type === component.type && c.isActive,
      ).length

      return (
        !isPaused &&
        !environment.reducedMotion &&
        environment.performanceLevel !== 'low' &&
        activeOfType < config.maxInstances
      )
    },
    [components, environment, isPaused],
  )

  // Utility functions
  const getStaggerDelay = useCallback((index: number, _total: number) => {
    const { baseDelay, maxDelay, maxItems } = STAGGER_CONFIG
    const adjustedIndex = Math.min(index, maxItems)
    return Math.min(baseDelay * adjustedIndex, maxDelay)
  }, [])

  const getScrollProgress = useCallback(
    (element?: HTMLElement) => {
      if (!element) return environment.scrollProgress

      const rect = element.getBoundingClientRect()
      return 1 - rect.bottom / (environment.viewportHeight + rect.height)
    },
    [environment.scrollProgress, environment.viewportHeight],
  )

  const getViewProgress = useCallback(
    (element: HTMLElement) => {
      const rect = element.getBoundingClientRect()
      return 1 - rect.bottom / environment.viewportHeight
    },
    [environment.viewportHeight],
  )

  const value: MotionContextType = {
    environment,
    registerComponent,
    unregisterComponent,
    shouldAnimate,
    getStaggerDelay,
    canParallax: environment.performanceLevel === 'high' && !environment.reducedMotion,
    canInfinite: environment.performanceLevel === 'high' && !environment.reducedMotion,
    canGesture: !environment.reducedMotion,
    getScrollProgress,
    getViewProgress,
    isPaused,
    togglePause: () => setIsPaused((p) => !p),
  }

  return <MotionContext.Provider value={value}>{children}</MotionContext.Provider>
}

// Custom hook to use motion context
export const useMotion = () => {
  const context = useContext(MotionContext)
  if (!context) {
    throw new Error('useMotion must be used within a MotionProvider')
  }
  return context
}

// Export types
export type * from './types'
