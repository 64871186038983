'use client'

import React from 'react'
import { HydrateCMSLinks } from '@/components/core/CMSLink/Hydrate'

interface CMSLinkProviderProps {
  children: React.ReactNode
}

export const CMSLinkProvider: React.FC<CMSLinkProviderProps> = ({ children }) => {
  return (
    <>
      <HydrateCMSLinks />
      {children}
    </>
  )
}
