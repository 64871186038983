'use client'

/**
 * @fileoverview Hook to make a container element (like a card) behave like a clickable link,
 * activating an inner anchor element while ignoring clicks on other interactive elements within the container.
 *
 * @module hooks/useClickableCard
 */

import type { RefObject } from 'react'
import { useRouter } from 'next/navigation'
import { useCallback, useEffect, useRef } from 'react'

/**
 * Configuration options for the useClickableCard hook.
 */
interface UseClickableCardProps {
  /** Should the link be treated as external (opens using window.open)? @default false */
  external?: boolean
  /** Should the link open in a new tab? (target="_blank") @default false */
  newTab?: boolean
  /** Should Next Router scroll to the top on navigation? @default true */
  scroll?: boolean
}

/**
 * Return type for the useClickableCard hook.
 */
interface UseClickableCardReturn<T extends HTMLElement> {
  /** Ref to be attached to the clickable container element. */
  cardRef: RefObject<T | null>
  /** Ref to be attached to the primary anchor element inside the container. */
  linkRef: RefObject<HTMLAnchorElement | null>
}

/**
 * Makes a container element (e.g., a card) clickable, triggering the navigation
 * associated with an inner anchor element (`linkRef`). It distinguishes between
 * a click on the card background and a click on interactive elements within the card,
 * only navigating when the click originates on the card background.
 *
 * Also adds keyboard support (Enter/Space) when the card element itself is focused.
 *
 * @template T - The type of the container HTMLElement (e.g., HTMLDivElement).
 * @param {UseClickableCardProps} options - Configuration options for the hook.
 * @returns {UseClickableCardReturn<T>} Refs to be attached to the card and link elements.
 *
 * @example
 * const MyCard = ({ href }) => {
 *   const { cardRef, linkRef } = useClickableCard<HTMLDivElement>({});
 *   return (
 *     <div ref={cardRef} tabIndex={0} style={{ border: '1px solid #ccc', padding: '1rem', cursor: 'pointer' }}>
 *       <h2>Card Title</h2>
 *       <p>Some content...</p>
 *       <Link href={href} ref={linkRef} style={{ color: 'blue' }}>
 *         Learn More
 *       </Link>
 *       <button onClick={(e) => { e.stopPropagation(); alert('Button clicked!'); }}>Nested Button</button>
 *     </div>
 *   );
 * };
 */
export function useClickableCard<T extends HTMLElement>({
  external = false,
  newTab = false,
  scroll = true,
}: UseClickableCardProps = {}): UseClickableCardReturn<T> {
  const router = useRouter()
  const cardRef = useRef<T>(null)
  const linkRef = useRef<HTMLAnchorElement>(null)

  // State refs to track interaction details
  const timeDown = useRef<number>(0) // Timestamp of mousedown
  const isClickOnInteractiveElement = useRef<boolean>(false) // Did the mousedown start on an interactive element?
  const pressedButton = useRef<number>(0) // Which mouse button was pressed?

  /**
   * Handles the mouse down event on the card.
   * Records the time and checks if the click started on an interactive element.
   */
  const handleMouseDown = useCallback((e: MouseEvent) => {
    const target = e.target as Element
    const closestInteractive = target?.closest(
      'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])',
    )

    pressedButton.current = e.button
    timeDown.current = Date.now()

    // Check if the click started directly on the linkRef or another interactive element
    isClickOnInteractiveElement.current =
      !!closestInteractive && closestInteractive !== cardRef.current // Allow click if card itself is focusable
  }, []) // No dependencies needed as it only interacts with refs

  /**
   * Handles the mouse up event on the card.
   * Triggers navigation if the click was short, not on an interactive element,
   * and was the primary mouse button.
   */
  const handleMouseUp = useCallback(
    (e: MouseEvent) => {
      // Only proceed if the primary button was pressed down
      if (pressedButton.current !== 0) return

      const target = e.target as Element
      const closestInteractive = target?.closest(
        'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])',
      )

      // Ensure mouseup also didn’t land on an interactive element different from the card itself
      const isReleaseOnInteractiveElement =
        !!closestInteractive && closestInteractive !== cardRef.current

      const href = linkRef.current?.href
      if (!href) return // No link to navigate to

      const timeNow = Date.now()
      const timeDifference = timeNow - timeDown.current

      // Check for conditions: short click duration, didn’t start/end on interactive, primary button, no Ctrl key
      if (
        timeDifference <= 250 && // Prevents triggering on drag
        !isClickOnInteractiveElement.current &&
        !isReleaseOnInteractiveElement &&
        !e.ctrlKey // Respect Ctrl+Click for new tab behavior handled by browser
      ) {
        // Trigger navigation
        triggerNavigation(href, external, newTab, scroll, router)
      }

      // Reset interaction state
      isClickOnInteractiveElement.current = false
      timeDown.current = 0
      pressedButton.current = 0
    },
    [external, newTab, router, scroll, linkRef], // Dependencies needed for triggerNavigation
  )

  /**
   * Handles keydown events for keyboard accessibility (Enter/Space).
   */
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const href = linkRef.current?.href
      if (!href) return

      // Check if Enter or Space was pressed and the event target is the card itself
      if ((e.key === 'Enter' || e.key === ' ') && e.target === cardRef.current) {
        e.preventDefault() // Prevent default space scroll / enter form submit
        triggerNavigation(href, external, newTab, scroll, router)
      }
    },
    [external, newTab, router, scroll, linkRef], // Dependencies needed for triggerNavigation
  )

  /**
   * Effect to attach event listeners to the card element.
   */
  useEffect(() => {
    const node = cardRef.current
    if (!node) return

    // Use AbortController for cleanup
    const abortController = new AbortController()
    const { signal } = abortController

    node.addEventListener('mousedown', handleMouseDown, { signal })
    node.addEventListener('mouseup', handleMouseUp, { signal })
    node.addEventListener('keydown', handleKeyDown, { signal })

    // Cleanup function
    return () => {
      abortController.abort()
    }
  }, [handleMouseDown, handleMouseUp, handleKeyDown]) // Re-attach if handlers change

  return {
    cardRef,
    linkRef,
  }
}

/**
 * Helper function to perform the navigation action.
 * Separated for clarity and reusability between mouse and keyboard events.
 */
function triggerNavigation(
  href: string,
  external: boolean,
  newTab: boolean,
  scroll: boolean,
  router: ReturnType<typeof useRouter>,
): void {
  if (external) {
    const target = newTab ? '_blank' : '_self'
    window.open(href, target)
  } else if (newTab) {
    // For internal links, simulate Ctrl+Click behavior if newTab is true
    window.open(href, '_blank')
  } else {
    // Standard internal navigation
    router.push(href, { scroll })
  }
}
