'use client'

import { useEffect } from 'react'
import type { SiteTheme } from '@/payload-types'
import { updateThemeVariables } from './updateThemeVariables'

/**
 * Hook to initialize and update site theme variables
 */
export const useSiteTheme = (theme: SiteTheme) => {
  useEffect(() => {
    updateThemeVariables(theme)
  }, [theme])
}
