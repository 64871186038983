'use client'

export * from './Layout'
export * from './Theme'
export * from './Motion'
export * from './PostHog'
export * from './CMSLink'

import React from 'react'
import { LayoutProvider } from './Layout'
import { ThemeProvider } from './Theme'
import { MotionProvider } from './Motion'
import { PostHogProvider } from './PostHog'
import { CMSLinkProvider } from './CMSLink'

export const RootProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <ThemeProvider>
      <LayoutProvider>
        <MotionProvider>
          <PostHogProvider>
            <CMSLinkProvider>{children}</CMSLinkProvider>
          </PostHogProvider>
        </MotionProvider>
      </LayoutProvider>
    </ThemeProvider>
  )
}
