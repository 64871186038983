"use client";

/**
 * @fileoverview Custom hook to delay updating a value until a specified delay
 * has passed since the last time the input value changed.
 */

import { useState, useEffect } from 'react'

/**
 * A custom hook that delays updating a value until a specified delay has passed
 * since the last change. Useful for reducing API calls or expensive operations
 * that shouldn't happen on every keystroke or rapid value change.
 *
 * @template T - The type of the value being debounced.
 * @param {T} value - The value to debounce.
 * @param {number} [delay=200] - The delay in milliseconds before updating the debounced value. Defaults to 200ms.
 * @returns {T} The debounced value, which updates only after the specified delay has passed
 *              without the input `value` changing.
 *
 * @example
 * const [searchTerm, setSearchTerm] = useState('');
 * // Debounce the searchTerm with a 500ms delay
 * const debouncedSearch = useDebounce(searchTerm, 500);
 *
 * useEffect(() => {
 *   // This effect runs only when debouncedSearch changes (after 500ms of inactivity)
 *   if (debouncedSearch) {
 *     fetch(`/api/search?q=${debouncedSearch}`);
 *   }
 * }, [debouncedSearch]);
 */
export const useDebounce = <T>(value: T, delay = 200): T => {
  // State to store the debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    // Set up a timer to update the debounced value after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    // Cleanup function: Clear the timeout if the value changes or the component unmounts
    // This is the core of the debounce logic – preventing updates if changes occur within the delay period.
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay]) // Re-run the effect if the input value or the delay changes

  // Return the latest debounced value
  return debouncedValue
}