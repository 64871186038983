'use client'

import type { SiteTheme } from '@/payload-types'

/**
 * Formats a layout value with its unit for CSS
 */
const formatLayoutValue = (value: number | undefined, unit: string | undefined, defaultValue: string): string => {
  if (typeof value !== 'number' || !unit) return defaultValue
  return `${value}${unit}`
}

/**
 * Updates CSS variables based on the current site theme settings
 * This ensures that layout settings are properly applied
 */
export const updateThemeVariables = (theme: SiteTheme) => {
  if (!theme?.layout) return

  const root = document.documentElement
  const { layout } = theme

  // Update content max width
  const contentMax = formatLayoutValue(
    layout.contentMax?.value,
    layout.contentMax?.unit,
    '80rem'
  )
  root.style.setProperty('--content-max', contentMax)

  // Update minimum gutter width
  const gutterMin = formatLayoutValue(
    layout.gutterMin?.value,
    layout.gutterMin?.unit,
    '2vw'
  )
  root.style.setProperty('--gutter-min', gutterMin)

  // Update edge padding
  const edgePadding = formatLayoutValue(
    layout.edgePadding?.value,
    layout.edgePadding?.unit,
    '1rem'
  )
  root.style.setProperty('--edge-padding', edgePadding)
}
