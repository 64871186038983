"use client";

/**
 * @fileoverview Custom hook to prevent scrolling on the body element.
 * Useful for modals, dialogs, or overlays. Restores scroll behavior on cleanup.
 * Manages multiple instances to prevent conflicts.
 */

import { canUseDOM } from '@/utils/dom';
import { useEffect, useId, useMemo } from 'react'; // Import useId and useMemo

/**
 * Utility function to calculate scrollbar width.
 * This helps prevent layout shifts when overflow is hidden.
 * @returns {number} The width of the scrollbar in pixels.
 */
const calculateScrollBarWidth = (): number => {
  if (!canUseDOM()) return 0; // Check DOM availability
  // Use the difference between window innerWidth and clientWidth
  // This is generally reliable and avoids creating temporary elements
  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
  return scrollbarWidth > 0 ? scrollbarWidth : 0; // Ensure non-negative
};

// Keys that trigger scrolling
const SCROLL_KEYS: Record<string, boolean> = {
  Space: true,
  ArrowUp: true,
  ArrowDown: true,
  PageUp: true,
  PageDown: true,
  Home: true,
  End: true,
};

/**
 * Options for configuring the scroll prevention behavior.
 */
interface UsePreventScrollOptions {
  /**
   * If true, allows scrolling using the keyboard (Arrow keys, Space, etc.).
   * Defaults to false (keyboard scrolling is prevented).
   */
  allowKeyboardScrolling?: boolean;
  /**
   * If true, applies 'overflow: hidden' to the body to prevent mouse/touch scrolling.
   * Also compensates for scrollbar width to prevent layout shifts.
   * Defaults to true.
   */
  preventOverflow?: boolean;
  /**
   * If true, prevents touchmove events on the body to disable touch scrolling.
   * Allows touch scrolling on elements specifically designed to be scrollable.
   * Defaults to true.
   */
  preventTouchMove?: boolean;
}

// --- Module-level state for managing multiple instances ---
const activeLocks = new Set<string>();
let originalStyles = { overflow: '', paddingRight: '' };
let currentOptions = { // Store options used when lock was initiated
  preventOverflow: true,
  preventTouchMove: true,
  allowKeyboardScrolling: false,
};

// --- Event Handlers (defined outside hook to maintain stable reference) ---
const preventTouchMoveHandler = (event: TouchEvent) => {
  // Check against the options stored when the lock cycle started
  if (!currentOptions.preventTouchMove) return;

  // Allow touchmove if the target or its parents have overflow: scroll/auto
  let target = event.target as HTMLElement;
  while (target && target !== document.body) {
    const style = window.getComputedStyle(target);
    if (style.overflowY === 'scroll' || style.overflowY === 'auto') {
      return; // Don't prevent if the target area is scrollable
    }
    target = target.parentElement as HTMLElement;
  }
  // Prevent default if the event target is the body or a non-scrollable element
  event.preventDefault();
};

const preventKeydownScrollHandler = (event: KeyboardEvent) => {
  // Check against the options stored when the lock cycle started
  if (currentOptions.allowKeyboardScrolling) return;

  // Allow keydown if target is an input, textarea, or contentEditable element
  const target = event.target as HTMLElement;
  if (
    target.isContentEditable ||
    target.tagName === 'INPUT' ||
    target.tagName === 'TEXTAREA' ||
    target.tagName === 'SELECT' // Allow dropdown navigation
  ) {
    return;
  }

  // Prevent default if the key is a scroll key
  if (SCROLL_KEYS[event.code || event.key]) {
    event.preventDefault();
  }
};

/**
 * Custom hook that prevents scrolling on the body element when active.
 * Manages multiple instances using a lock count.
 *
 * @param {boolean} isActive - Boolean indicating whether scroll prevention should be active.
 * @param {UsePreventScrollOptions} [options] - Optional configuration object.
 *
 * @example
 * // In a modal component:
 * import React, { useState } from 'react';
 * import { usePreventScroll } from '@/utils/hooks/usePreventScroll'; // Corrected import path alias
 *
 * function Modal() {
 *   const [isOpen, setIsOpen] = useState(false);
 *   usePreventScroll(isOpen); // Prevent scroll when isOpen is true
 *
 *   return (
 *     <div>
 *       <button onClick={() => setIsOpen(true)}>Open Modal</button>
 *       {isOpen && (
 *         <div className="modal-overlay">
 *           <div className="modal-content">
 *             Modal Content
 *             <button onClick={() => setIsOpen(false)}>Close</button>
 *           </div>
 *         </div>
 *       )}
 *     </div>
 *   );
 * }
 */
export const usePreventScroll = (
  isActive: boolean,
  options: UsePreventScrollOptions = {},
): void => {
  const instanceId = useId(); // Unique ID for this hook instance

  // Memoize options to prevent unnecessary effect runs
  const finalOptions = useMemo(() => ({
    allowKeyboardScrolling: options.allowKeyboardScrolling ?? false,
    preventOverflow: options.preventOverflow ?? true,
    preventTouchMove: options.preventTouchMove ?? true,
  }), [options.allowKeyboardScrolling, options.preventOverflow, options.preventTouchMove]);

  useEffect(() => {
    if (!canUseDOM()) return;

    const addLock = () => {
      if (activeLocks.has(instanceId)) return; // Already added by this instance

      const isFirstLock = activeLocks.size === 0;
      activeLocks.add(instanceId);

      if (isFirstLock) {
        // Store original styles *only* on the first lock
        originalStyles.overflow = document.body.style.overflow;
        originalStyles.paddingRight = document.body.style.paddingRight;

        // Store options used for this lock cycle (use finalOptions from the instance that initiated the lock)
        currentOptions = finalOptions;

        // Apply styles and listeners based on the options of the *first* locker
        if (currentOptions.preventOverflow) {
          const scrollBarWidth = calculateScrollBarWidth();
          document.body.style.overflow = 'hidden';
          if (scrollBarWidth > 0) {
            document.body.style.paddingRight = `${
              parseFloat(originalStyles.paddingRight || '0') + scrollBarWidth
            }px`;
          }
        }

        // Add listeners based on the options that started this lock cycle
        if (currentOptions.preventTouchMove) {
          document.body.addEventListener('touchmove', preventTouchMoveHandler, { passive: false });
        }
        if (!currentOptions.allowKeyboardScrolling) {
          window.addEventListener('keydown', preventKeydownScrollHandler, { capture: false });
        }
      }
    };

    const removeLock = () => {
      if (!activeLocks.has(instanceId)) return; // Lock not held by this instance or already removed

      activeLocks.delete(instanceId);

      if (activeLocks.size === 0) {
        // Restore original styles *only* on the last lock removal
        // Use the options stored when the lock cycle started (currentOptions)
        if (currentOptions.preventOverflow) {
          document.body.style.overflow = originalStyles.overflow;
          document.body.style.paddingRight = originalStyles.paddingRight;
        }

        // Always try to remove listeners, matching the options when added
        if (currentOptions.preventTouchMove) {
          document.body.removeEventListener('touchmove', preventTouchMoveHandler);
        }
        if (!currentOptions.allowKeyboardScrolling) {
          window.removeEventListener('keydown', preventKeydownScrollHandler);
        }

        // Reset stored values for the next cycle
        originalStyles = { overflow: '', paddingRight: '' };
        // Reset currentOptions to reflect a clean state
        currentOptions = {
          preventOverflow: true,
          preventTouchMove: true,
          allowKeyboardScrolling: false,
        };
      }
    };

    if (isActive) {
      addLock();
    } else {
      // Ensure lock is removed if isActive becomes false during the component's lifecycle
      removeLock();
    }

    // Cleanup function handles unmounting: always attempt to remove the lock
    return () => {
      removeLock();
    };
    // Re-run effect if isActive or specific options change, or instanceId (should be stable)
  }, [isActive, instanceId, finalOptions]);
};
