'use client'

import React, { createContext, useContext, useState, useCallback } from 'react'
import { cn } from '@/utils/ui'
import type { LayoutContextType, ContainerWidth, SpacingPreset, Breakpoint } from './types'
import { CONTAINER_SIZES, SPACING_VALUES, BREAKPOINT_ORDER, BREAKPOINT_VALUES } from './constants'

// Create the context
const LayoutContext = createContext<LayoutContextType | null>(null)

// Provider component
export const LayoutProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Breakpoint state
  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoint>('mobile')

  // Utility functions
  const getContainerClass = useCallback((width: ContainerWidth = 'content') => {
    return cn('mx-auto w-full px-4 md:px-6 lg:px-8', {
      'max-w-[40rem]': width === 'narrow',
      'max-w-[60rem]': width === 'content',
      'max-w-[80rem]': width === 'wide',
      'max-w-full': width === 'full',
    })
  }, [])

  const getSpacingClass = useCallback((preset: SpacingPreset = 'medium') => {
    return `p-[${SPACING_VALUES[preset]}]`
  }, [])

  const isAboveBreakpoint = useCallback(
    (breakpoint: Breakpoint) => {
      return BREAKPOINT_ORDER.indexOf(currentBreakpoint) >= BREAKPOINT_ORDER.indexOf(breakpoint)
    },
    [currentBreakpoint],
  )

  const getColumnClass = useCallback((span: number) => {
    return `col-span-${span}`
  }, [])

  // Effect to update breakpoint based on window size
  React.useEffect(() => {
    const updateBreakpoint = () => {
      const width = window.innerWidth
      if (width < BREAKPOINT_VALUES.tablet) setCurrentBreakpoint('mobile')
      else if (width < BREAKPOINT_VALUES.desktop) setCurrentBreakpoint('tablet')
      else if (width < BREAKPOINT_VALUES.wide) setCurrentBreakpoint('desktop')
      else setCurrentBreakpoint('wide')
    }

    // Initial check
    updateBreakpoint()

    // Add resize listener
    window.addEventListener('resize', updateBreakpoint)
    return () => window.removeEventListener('resize', updateBreakpoint)
  }, [])

  const value: LayoutContextType = {
    containerSizes: CONTAINER_SIZES,
    getContainerClass,
    spacing: SPACING_VALUES,
    getSpacingClass,
    currentBreakpoint,
    isAboveBreakpoint,
    gridColumns: 12,
    getColumnClass,
  }

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

// Custom hook to use layout context
export const useLayout = () => {
  const context = useContext(LayoutContext)
  if (!context) {
    throw new Error('useLayout must be used within a LayoutProvider')
  }
  return context
}

// Export types
export type { LayoutContextType, ContainerWidth, SpacingPreset, Breakpoint }
