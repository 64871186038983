'use client'

import React from 'react'
import { ClientCMSLinkHydration } from './client'

/**
 * This component should be added to the app's client layout
 * to hydrate all CMS links with client-side behavior.
 * It finds server-rendered CMSLinks with event handlers and
 * replaces them with interactive client components.
 */
export function HydrateCMSLinks() {
  return <ClientCMSLinkHydration />
}
